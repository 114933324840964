<template>
  <div class="storagePay">
    <h1>仓储申请付款</h1>
    <el-form :inline="true">
      <el-form-item label="申请日期">
        <el-date-picker v-model="searchParam.applyDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchParam.reviewState" filterable style="width: 120px;" clearable>
          <el-option label="未审核" value="none" />
          <el-option label="通过" value="pass" />
          <el-option label="不通过" value="unpass" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="add()">新建</el-button>
          <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">详情</el-button>
          <el-button type="info" :disabled="!(show.selected.length == 1)" @click="review()">审核</el-button>
          <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del()">删除</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border show-summary :summary-method="summary" style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column width="55" align="center">
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">详情</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="applyDate" label="申请日期" width="120" show-overflow-tooltip />
      <el-table-column prop="storage" label="仓储" show-overflow-tooltip />
      <el-table-column prop="useto" label="用途" show-overflow-tooltip />
      <el-table-column prop="amount" label="金额" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.amount) }}
        </template>
      </el-table-column>
      <el-table-column prop="reviewState" label="审核状态" width="120" show-overflow-tooltip>
        <template #default="scope">
          <el-text v-if="scope.row.reviewState == 'none'">未审核</el-text>
          <el-text type="success" v-if="scope.row.reviewState == 'pass'">通过</el-text>
          <el-text type="danger" v-if="scope.row.reviewState == 'unpass'">不通过</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="reviewNote" label="审核备注" show-overflow-tooltip />
      <el-table-column prop="hasPayment" label="打款状态" show-overflow-tooltip>
        <template #default="scope">
          <el-text v-if="scope.row.hasPayment == true">已打款</el-text>
          <el-text v-if="scope.row.hasPayment == false">未打款</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="paymentReceipt" label="打款回单" width="120" show-overflow-tooltip>
        <template #default="scope">
          <el-text v-if="scope.row.paymentReceipt">有</el-text>
          <el-text v-else>无</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="invoice" label="发票" width="120" show-overflow-tooltip>
        <template #default="scope">
          <el-text v-if="scope.row.invoice">有</el-text>
          <el-text v-else>无</el-text>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
  summary: {},
})

const show = reactive({
  selected: [],
})


const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 50
  searchParam.applyDates = []
  searchParam.reviewState = null
}
resetSearchParam()
const beforeSearchParam = cache.getObject(cache.keys.pageParam + 'storagePay')
if (beforeSearchParam) {
  Object.assign(searchParam, beforeSearchParam)
  cache.setObject(cache.keys.pageParam + 'storagePay', null)
}


const commitFindList = () => {
  const params = {}
  params.pageNum = searchParam.pageNum
  params.pageSize = searchParam.pageSize
  if (searchParam.applyDates && searchParam.applyDates.length == 2) {
    params.applyDateGe = util.parseTime(searchParam.applyDates[0], '{y}-{m}-{d}')
    params.applyDateLe = util.parseTime(searchParam.applyDates[1], '{y}-{m}-{d}')
  }
  params.reviewState = searchParam.reviewState
  api.get('/backend/storagePay/page', { params: params }).then(res => {
    data.list = res.list
    data.total = res.total
    data.summary = res.data
  })
}
commitFindList()

const summary = () => {
  return ['合计', '', '', '', '', math.formatNumber(data.summary.amount)]
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'storagePay', searchParam)
  cache.setObject(cache.keys.pageParam + 'storagePayDetail', null)
  router.push('/storagePayDetail')
}

const update = (row) => {
  cache.setObject(cache.keys.pageParam + 'storagePay', searchParam)
  const storagePay = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'storagePayDetail', storagePay)
  router.push('/storagePayDetail')
}

const review = () => {
  const storagePay = tabRef.value.getSelectionRows()[0]
  if (storagePay.reviewState != 'none') {
    ElMessage.error('此单已审核')
    return
  }
  cache.setObject(cache.keys.pageParam + 'storagePay', searchParam)
  cache.setObject(cache.keys.pageParam + 'storagePayReview', storagePay)
  router.push('/storagePayReview')
}

const del = () => {
  const ids = tabRef.value.getSelectionRows().map(storagePay => storagePay.id)
  ElMessageBox.confirm('', {
    title: '确认删除',
    message: '将要删除选中的 ' + ids.length + ' 项。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/storagePay/del', { ids: ids }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}
</script>

<style lang="less"></style>